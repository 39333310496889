<template>
  <div class="product">
    <!-- 面包屑 -->
    <woocommerce-breadcrumb></woocommerce-breadcrumb>
    <!-- 商品详情  -->
    <product-details
      :goodDetilData="goodDetilData"
      :isShowLoading="isShowLoading"
      :specArr="specArr"
      :specSku="specSku"
    ></product-details>
    <!-- tab栏 -->
    <woocommerce-tabs
      :goodDetilData="goodDetilData"
      :isShowLoading="isShowLoading"
    ></woocommerce-tabs>
    <!-- 关联产品 -->
    <related-products
      :goodlist="goodlist"
      v-if="!!goodlist.length"
    ></related-products>
  </div>
</template>
<script>
import { Goods } from "@/model/Goods.js";
import {
  WoocommerceBreadcrumb,
  ProductDetails,
  WoocommerceTabs,
  RelatedProducts,
} from "./child";

export default {
  name: "Product",
  data() {
    return {
      //商品数据
      goodDetilData: {
        isBegin: true,
      },

      //商品列表
      goodlist: [],

      specArr: [],
      specSku: [],

      // 是否显示加载
      isShowLoading: true,
    };
  },

  watch: {
    "$route.query.product_id": {
      handler(newName, oldName) {
        this.goodDetil();
      },
      immediate: true,
    },
  },

  methods: {
    //获取商品详情
    goodDetil() {
      this.isShowLoading = true;
      //商品数据
      this.goodDetilData = {
        isBegin: true,
      };
      this.goodlist = [];
      Goods.Detail({
        data: {
          service: "goods.detail",
          product_id: this.$route.query.product_id,
        },
        succ: (res, all) => {
          this.isShowLoading = false;
          this.goodDetilData = res.goods;
          this.goodlist = res.relate_goods ? res.relate_goods : res.promotion;
          res.specArr && (this.specArr = res.specArr);
          res.specSku && (this.specSku = res.specSku);
        },
        fail: (res, all) => {
          this.isShowLoading = false;
          this.$message("error", all.msg);
        },
      });
    },
  },

  components: {
    WoocommerceBreadcrumb,
    ProductDetails,
    WoocommerceTabs,
    RelatedProducts,
  },

  created() {
    // this.goodDetil();
  },
};
</script>
<style lang='scss' scoped>
.product {
  background-color: var(--bg-color-hint);
  padding-bottom: 3.25rem;
}
</style>