<template>
  <div class="tab">
    <ul class="menu" ref="menu"></ul>
    <div class="content" ref="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.init();
  },

  methods: {
    init() {
      let child = this.$refs.content.children;
      let length = child.length;
      for (let i = 0; i < length; i++) {
        let li = document.createElement("li");
        li.innerHTML = child[i].myname;
        li.className = "myli";
        li.num = i;
        li.addEventListener("click", () => {
          this.remove();
          li.classList.add("myactive");
          this.$emit("changeChoose", i);
        });
        if (i == 0) {
          li.classList.add("myactive");
        }
        this.$refs.menu.appendChild(li);
      }
    },

    //移除所有类名
    remove() {
      for (let i = 0; i < this.$refs.menu.children.length; i++) {
        this.$refs.menu.children[i].classList.remove("myactive");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.tab {
  border: 1px solid #edeef5;
  background-color: white;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  .menu {
    display: flex;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    /deep/ .myli {
      list-style: none;
      margin-right: 1.25rem;
      cursor: pointer;
      color: #c2c2d3;
      &:hover {
        color: #352435;
      }
    }
    /deep/ .myactive {
      color: #352435;
    }

    position: relative;
    &::after {
      content: "";
      position: absolute;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      width: auto;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #edeef5;
    }
  }

  .content{
      padding-top:1.25rem;
      padding-bottom:1.25rem;
  }
}
</style>