<template>
  <div class="UserComment">
    <!-- 添加评论 -->
    <div class="addComment">
      <div class="title">Add Comment</div>
      <el-form
        :model="commentInfo"
        label-width="150px"
        label-position="top"
        ref="refForm"
        :rules="rules"
      >
        <el-form-item label="Rate：" prop="rate">
          <el-rate v-model="commentInfo.rate"></el-rate>
        </el-form-item>
        <el-form-item label="Comment：" prop="comment">
          <el-input
            type="textarea"
            :rows="5"
            v-model="commentInfo.comment"
          ></el-input>
        </el-form-item>
        <el-form-item label="Name：" prop="name">
          <el-input type="text" v-model="commentInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="Email：" prop="email">
          <el-input type="email" v-model="commentInfo.email"></el-input>
        </el-form-item>
      </el-form>

      <div class="agree">
        <my-checkbox
          :isGroup="false"
          v-model="isSaveInfo"
          :color="{ active: '#ffa41c', unactive: '#6c757d' }"
          ><span class="agreeFont"
            >Save the email and name to use next time</span
          ></my-checkbox
        >
      </div>

      <div class="submit" @click="submit">Submit</div>
    </div>
  </div>
</template>
<script>
import MyCheckbox from "@/components/common/checkbox/MyCheckbox.vue";
import { Goods } from "@/model/Goods.js";
import { mapGetters } from "vuex";
export default {
  name: "UserComment",
  components: {
    MyCheckbox,
  },
  props: {
    goodDetilData: {
      type: Object,
    },
  },
  data() {
    return {
      isSaveInfo: true,
      commentInfo: {
        rate: 0,
        comment: "",
        name: "",
        email: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserCommentName", "getUserCommentEmail"]),
    rules() {
      return {
        rate: [
          {
            validator: this.ratePass,
            trigger: "change",
            required: true,
          },
        ],
        comment: [
          {
            validator: this.pass,
            trigger: "change",
            required: true,
          },
        ],
        name: [
          {
            validator: this.pass,
            trigger: "change",
            required: true,
          },
        ],
        email: [
          {
            validator: this.pass,
            trigger: "change",
            required: true,
          },
        ],
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.commentInfo.name = this.getUserCommentName;
      this.commentInfo.email = this.getUserCommentEmail;
    },
    sendComment() {
      Goods.sendComment({
        data: {
          comments: JSON.stringify([
            {
              goods_id: this.goodDetilData.goods_id,
              order_id: 0,
              comment: this.commentInfo.comment,
              images: "",
              author: this.commentInfo.name,
              email: this.commentInfo.email,
              score: this.commentInfo.rate,
            },
          ]),
        },
        succ: (res, all) => {
          this.$message("success", all.msg);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    pass(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error("请输入内容！"));
      } else {
        callback();
      }
    },
    ratePass(rule, value, callback) {
      if (Number(value) === 0) {
        callback(new Error("请选择评分！"));
      } else {
        callback();
      }
    },

    checkEmpty(callback) {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          callback();
          return;
        }
        this.$message("error", "存在未填写的内容，请检查");
        return false;
      });
    },
    submit() {
      this.checkEmpty(() => {
        if (this.isSaveInfo) {
          localStorage.setItem("userCommentName", this.commentInfo.name);
          localStorage.setItem("userCommentEmail", this.commentInfo.email);
        }
        this.sendComment();
      });
    }
  },
};
</script>
<style lang='scss' scoped>
.addComment {
  margin-top: 60px;
  .title {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color-title);
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-color-default);
    margin-bottom: 20px;
  }

  .agree {
    display: flex;
    .agreeFont {
      font-size: 0.75rem;
      color: var(--text-color-minor);
      margin-left: 0.5rem;
    }
  }

  .submit {
    margin-top: 15px;
    text-align: center;
    line-height: 36px;
    width: 80px;
    height: 36px;
    border-radius: 2px;
    background-color: var(--main-color);
    color: white;
    font-size: 0.875rem;
    &:hover {
      background-color: var(--main-hover-color);
      cursor: pointer;
    }
  }
}
.UserComment {
  //  border-top: 1px solid #ccc;
  padding-top: 10px;
}
</style>