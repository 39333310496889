<template>
  <div class="woocommerce">
    <div class="container">
      <my-breadcrumb>
        <my-breadcrumb-item to="/">{{$t('Goods.Pages.Home')}}</my-breadcrumb-item>
        <my-breadcrumb-item to="/product/index" :active="true" :isDisabled="true"
          >{{$t('Goods.Pages.Product')}}</my-breadcrumb-item
        >
      </my-breadcrumb>
    </div>
  </div>
</template>
<script>
import { MyBreadcrumb, MyBreadcrumbItem } from "common/breadcrumb";
export default {
  data() {
    return {};
  },
  created() {},
  components: {
    MyBreadcrumb,
    MyBreadcrumbItem,
  },
};
</script>
<style lang='scss' scoped>
.woocommerce {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
</style>