<template>
  <div class="relatedProducts">
    <div class="container">
      <div class="title">{{$t('Goods.Pages.RelatedProducts')}}</div>
      <div class="productList">
        <div class="item" v-for="item in goodlist" :key="item.name">
          <product-item :productData="item">
            <template v-slot:img>
              <img :src="item.image" alt="" />
            </template>
            <template v-slot:title>
              {{ $cl("name", item, $i18n.locale) }}
            </template>
            <!-- <template v-slot:tag>{{ item.onSale ? "在售" : "下架" }}</template> -->
                <template v-slot:sell_count>
              {{ item.sell_count ? $t('Product.Pages.SaleCount') + item.sell_count : ''}}
            </template>
            <template v-slot:oldprice>
              {{ item.price_text }}
            </template>
            <template v-slot:newprice>
              {{ item.price_text }}
            </template>
            <template v-slot:productBadges> 15% </template>
          </product-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductItem from "content/product/ProductItem";
import Cart from "@/model/Cart";
export default {
  data() {
    return {};
  },
  created() {},
  components: {
    ProductItem,
  },
  methods: {},
  props: {
    goodlist: {
      type: Array,
    },
  },
};
</script>
<style lang='scss' scoped>


.relatedProducts {
  margin-top: 3.25rem;

  .title {
    color: var(--text-color-title);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }

  .productList {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #eee9f0;
    border-top: 1px solid #eee9f0;

    .item {
      width: 25%;
    }
  }
}
</style>