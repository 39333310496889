<template>
  <div class="tabitem" ref="tabitem" :class="{active:choose==num}">
      <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$refs.tabitem.myname = this.name;
  },
  props: {
    name: {
      type: String,
      default: "",
    },

    num:{
        type:Number
    },

    choose:{
        num:Number
    }
  },
};
</script>
<style lang='scss' scoped>
.tabitem {
  display: none;
}

.active{
    display: block;
}
</style>