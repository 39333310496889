<template>
  <div class="tab">
    <div class="container">
      <div class="wrap">
        <el-tabs v-model="choose" type="card">
          <el-tab-pane :label="$t('Product.Pages.Introduce')" name="intro">
            <div class="main">
              <div
                class="page1cont"
                v-html="$cl('intro', goodDetilData, $i18n.locale)"
                v-if="goodDetilData.intro"
              ></div>

              <new-empty
                v-if="state == 'empty'"
                image="empty"
                :decripe="$t('Product.Msg.Empty')"
              ></new-empty>
              <new-empty
                v-if="state == 'err'"
                image="defErr"
                :decripe="$t('Product.Msg.Err')"
              ></new-empty>

              <def-loading :isshow="isShowLoading"></def-loading>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('Ordertail.Common.Evaluate') + ` (${commentsNum})`"
            name="comment"
          >
            <comment-list
              v-if="goodDetilData.goods_id"
              :goodDetilData="goodDetilData"
              @setCommentsNum="setCommentsNum"
            />
          </el-tab-pane>
        </el-tabs>
        <user-comment
          v-if="goodDetilData.goods_id"
          :goodDetilData="goodDetilData"
        />
      </div>

      <!-- <tab @changeChoose="changeChoose">
        <tab-item :name="$t('Product.Pages.Introduce')" :num="0" :choose="choose">
          <div class="main">
            <div
              class="page1cont"
              v-html="$cl('intro',goodDetilData, $i18n.locale)"
              v-if="goodDetilData.intro"
            ></div>

            <new-empty
              v-if="state == 'empty'"
              image="empty"
              :decripe="$t('Product.Msg.Empty')"
            ></new-empty>
            <new-empty
              v-if="state == 'err'"
              image="defErr"
              :decripe="$t('Product.Msg.Err')"
            ></new-empty>

            <def-loading :isshow="isShowLoading"></def-loading>
            <user-comment :needCommentList="false" v-if="goodDetilData.goods_id" :goodDetilData="goodDetilData" />
          </div>
        </tab-item>
        <tab-item :name="$t('Ordertail.Common.Evaluate')+` (${commentsNum})`" :num="1" :choose="choose">
          <user-comment v-if="goodDetilData.goods_id" :goodDetilData="goodDetilData" @setCommentsNum="setCommentsNum"  />
        </tab-item>
      </tab> -->
    </div>
  </div>
</template>
<script>
import { Tab, TabItem } from "common/tab";
import UserComment from "./woocommerceTabsChild/UserComment.vue";
import CommentList from "./woocommerceTabsChild/CommentList.vue";
import NewEmpty from "@/components/common/empty/NewEmpty";
import DefLoading from "@/components/common/loading/def/DefLoading";
export default {
  components: {
    Tab,
    TabItem,
    NewEmpty,
    DefLoading,
    UserComment,
    CommentList,
  },
  props: {
    goodDetilData: {
      type: Object,
    },
    isShowLoading: Boolean,
  },
  data() {
    return {
      choose: "intro",
      commentsNum: 0,
    };
  },
  computed: {
    state() {
      // 网络请求完毕，但没有数据，则判定请求失败
      if (!this.isShowLoading && this.goodDetilData.isBegin) {
        return "err";
      } else if (
        !this.isShowLoading &&
        !this.goodDetilData.isBegin &&
        this.goodDetilData.intro == ""
      ) {
        return "empty";
      }
    },
  },

  methods: {
    setCommentsNum(num) {
      this.commentsNum = num;
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.tab {
  margin-top: 3.25rem;
  .container {
    .wrap {
      background-color: white;
      padding: 20px;
    }
  }
  .main {
    min-height: 400px;
    position: relative;
    .page1cont {
      font-size: 0.875rem;
      color: var(--text-color-title);
      line-height: 1.75rem;
    }
  }
}
</style>