<template>
  <div class="CommentList">
    <!-- 评论列表 -->
    <div class="commentList">
      <div class="title">Comments</div>
      <div class="commentItem" v-for="item in commentList" :key="item.id">
        <div class="avatar">
          <!-- <img v-lazy="item.avatar" alt="" /> -->
          <img :src="require('@/assets/img/user/person.png')" alt="" />
        </div>
        <div class="commentMain">
          <el-rate
            v-model="item.score * 1"
            disabled
            show-score
            text-color="#ff9900"
          >
          </el-rate>
          <p class="userInfo">
            <span class="name">{{ item.author }} - </span
            ><span class="date">{{ item.createtime_text }}</span>
          </p>
          <p class="comment">
            {{ item.comment }}
          </p>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination" v-if="commentList.length">
        <pagination
          :totalPage="pages"
          v-model="page"
          @changePage="changePage"
        ></pagination>
      </div>
      <new-empty
        v-if="commentList.length === 0"
        image="empty"
        :decripe="$t('Product.Msg.Empty')"
      ></new-empty>
    </div>
  </div>
</template>
<script>
import { Goods } from "@/model/Goods.js";
import NewEmpty from "@/components/common/empty/NewEmpty";
import Pagination from "common/pagination/Pagination";
export default {
  name: "CommentList",
  components: {
    NewEmpty,
    Pagination,
  },
  props: {
    goodDetilData: {
      type: Object,
    },
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      more: 0,
      pages: 0,
      commentList: [],
    };
  },
  created() {
    this.getCommentList()
  },
  methods: {
    getCommentList() {
      Goods.Comment({
        data: {
          page: this.page,
          limit: this.limit,
          goods_id: this.goodDetilData.goods_id,
          // goods_id:168,
        },
        succ: (res, all) => {
          this.commentList = res.commentlist;
          this.total = res.paginated.total;
          this.pages = res.paginated.pages;
          this.more = res.paginated.more;
          this.$emit("setCommentsNum", this.total);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },
      changePage(page) {
      this.page = page;
      this.getCommentList();
    },
  },
};
</script>
<style lang='scss' scoped>
.commentList {
  .title {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color-title);
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  .commentItem {
    margin-bottom: 25px;
    display: flex;
    .avatar {
      width: 60px;
      margin-right: 15px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .commentMain {
      width: 100%;
      font-size: 0.875rem;
      color: var(--text-color-minor);
      .userInfo {
        .name {
          font-weight: 600;
        }
        margin: 5px 0;
      }
    }
  }
}


.pagination {
  display: flex;
  justify-content: center;
}
</style>